import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Facebook, Twitter, Instagram, Linkedin, Send, ChevronUp, MapPin, Phone, Mail } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Subscribed:', email);
    setEmail('');
    // Handle newsletter subscription logic here
  };

  const footerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const socialIcons = [
    { Icon: Facebook, href: 'https://facebook.com' },
    { Icon: Twitter, href: 'https://twitter.com' },
    { Icon: Instagram, href: 'https://instagram.com' },
    { Icon: Linkedin, href: 'https://linkedin.com' },
  ];

  const quickLinks = [
    { name: 'About Us', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Blog', href: '/blog' },
  ];

  const legalLinks = [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
    { name: 'Cookie Policy', href: '/cookies' },
    { name: 'Disclaimer', href: '/disclaimer' },
  ];

  return (
    <motion.footer 
      className="bg-gradient-to-b from-gray-900 to-black text-white py-12"
      initial="hidden"
      animate="visible"
      variants={footerVariants}
    >
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <motion.div variants={itemVariants}>
            <h3 className="text-2xl font-bold mb-4">TRIPOSCA</h3>
            <p className="text-gray-400 mb-4">Embark on unforgettable journeys with TRIPOSCA. Your dream destinations await!</p>
            <div className="flex space-x-4">
              {socialIcons.map(({ Icon, href }, index) => (
                <motion.a
                  key={index}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                  whileHover={{ scale: 1.2, rotate: 5 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Icon size={24} />
                </motion.a>
              ))}
            </div>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {quickLinks.map((link, index) => (
                <motion.li key={index} whileHover={{ x: 5 }}>
                  <a href={link.href} className="text-gray-400 hover:text-white transition-colors duration-300">{link.name}</a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="text-xl font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              {legalLinks.map((link, index) => (
                <motion.li key={index} whileHover={{ x: 5 }}>
                  <a href={link.href} className="text-gray-400 hover:text-white transition-colors duration-300">{link.name}</a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="text-xl font-semibold mb-4">Newsletter</h4>
            <p className="text-gray-400 mb-4">Stay updated with our latest offers and travel tips!</p>
            <form onSubmit={handleSubmit} className="flex">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="bg-gray-800 text-white px-4 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                required
              />
              <motion.button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-r-md"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Send size={20} />
              </motion.button>
            </form>
          </motion.div>
        </div>

        <motion.hr variants={itemVariants} className="border-gray-800 mb-8" />

        <motion.div variants={itemVariants} className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 mb-4 md:mb-0">&copy; 2024 TRIPOSCA PVT LTD. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="/contact" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center">
              <Mail size={16} className="mr-2" /> contact@triposca.in
            </a>
          </div>
        </motion.div>

        {/* <motion.button
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <ChevronUp size={24} />
        </motion.button> */}
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-gray-800 py-4"
          >
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap justify-between">
                <motion.div variants={itemVariants} className="w-full md:w-1/2 lg:w-1/3 mb-4">
                  <h5 className="text-lg font-semibold mb-2">Our Mission</h5>
                  <p className="text-gray-400">To provide unforgettable travel experiences and exceptional service to our valued customers.</p>
                </motion.div>
                <motion.div variants={itemVariants} className="w-full md:w-1/2 lg:w-1/3 mb-4">
                  <h5 className="text-lg font-semibold mb-2">Awards & Recognition</h5>
                  <ul className="text-gray-400">
                    <li>Best Travel Agency 2023</li>
                    <li>Customer Satisfaction Award 2022</li>
                    <li>Innovative Travel Solutions 2021</li>
                  </ul>
                </motion.div>
                <motion.div variants={itemVariants} className="w-full md:w-1/2 lg:w-1/3 mb-4">
                  <h5 className="text-lg font-semibold mb-2">Our Location</h5>
                  <p className="text-gray-400 flex items-center">
                    <MapPin size={16} className="mr-2" />
                    123 Travel Street, Adventure City, TC 12345
                  </p>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.footer>
  );
};

export default Footer;